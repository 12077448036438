import React, { useEffect, useState } from "react";
import axios from "axios";
import "./App.css";

const App: React.FC = () => {
  const [message, setMessage] = useState<string>("");

  useEffect(() => {
    axios
      .get("/api/data")
      .then((response) => {
        const json = response.data;
        setMessage(json.message);
      })
      .catch((error) => {
        console.error("There was an error fetching data!", error);
      });
  }, []);

  return (
    <div className="App">
      <h1>Message from Server:</h1>
      <p>{message}</p>
    </div>
  );
};

export default App;
